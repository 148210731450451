<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <Spinner :spin="isProcessing" />
    <!-- Header -->
    <section class="header profile_edit">
      <div class="flexH width">
        <a
          class="icon iconButton third"
          @click.prevent="$router.push('/profile')"
        >
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap">手機載具</span>
      <div class="flexH width right">
        <!-- <a class="textButton fM third" @click.prevent="popDelete">解除綁定</a> -->
      </div>
    </section>
    <!-- Main -->
    <section class="main receipt">
      <ValidationObserver v-slot="{ valid, passes }">
        <div class="info flexV width padding center gapM carrier">
          <div class="input right rounded">
            <!-- class: error -->
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-phone"></font-awesome-icon>
            </div>
            <span class="fM nowrap gray"
              >手機號碼<span class="red">*</span></span
            >
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.phoneNumber"
              disabled
            />
            <!-- <span class="fS error message">必填</span> -->
          </div>
          <ValidationProvider
            slim
            rules="required|isCarrierBarcode"
            v-slot="{ invalid, errors }"
          >
            <div
              :class="[
                'input',
                'right',
                'rounded',
                { error: invalid && errors[0] },
              ]"
            >
              <!-- class: error -->
              <div class="icon">
                <font-awesome-icon
                  icon="fa-solid fa-barcode"
                ></font-awesome-icon>
              </div>
              <span class="fM nowrap gray"
                >載具條碼<span class="red">*</span></span
              >
              <input
                type="text"
                class="fM"
                placeholder=""
                v-model="userData.cardNo"
              />
              <span class="fS error message">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <!-- <ValidationProvider
            slim
            rules="required"
            v-slot="{ invalid, errors }"
          >
            <div
              :class="[
                'input',
                'right',
                'rounded',
                { error: invalid && errors[0] },
              ]"
            >

              <div class="icon">
                <font-awesome-icon icon="fa-solid fa-key"></font-awesome-icon>
              </div>
              <span class="fM nowrap gray"
                >載具驗證碼<span class="red">*</span></span
              >
              <input
                type="password"
                class="fM"
                placeholder=""
                v-model="cardEncrypt"
              />
              <span class="fS error message">{{ errors[0] }}</span>
            </div>
          </ValidationProvider> -->
          <!-- <a
            class="textButton fM gray width text-right"
            href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC511W/"
            >忘記驗證碼</a
          > -->
          <a class="button rounded w-90" @click.prevent="save(valid, passes)">
            <div class="icon">
              <font-awesome-icon
                icon="fa-solid fa-floppy-disk"
              ></font-awesome-icon>
            </div>
            <span class="fM">儲存</span>
            <!-- 提交申請 -->
          </a>
          <span class="fS gray"
            >載具條碼請至
            <a
              class="textButton"
              href="https://www.einvoice.nat.gov.tw/APCONSUMER/BTC501W/"
              >財政部電子發票整合服務平台</a
            >
            進行申請
          </span>
        </div>
      </ValidationObserver>
      <div v-if="originalCardNo" class="barcode flexV width center">
        <barcode
          :value="originalCardNo"
          format="code128"
          width="2.5"
          height="60px"
          displayValue="false"
          :margin="5"
        >
        </barcode>
        <span class="fS">{{ originalCardNo }}</span>

        <!-- <div class="img"><img src="img/barcode.png" /></div>
        <span class="fS">{{ userData.cardNo }}</span> -->
      </div>
    </section>
    <!-- Popup (載具綁定成功) -->
    <section id="popSuccess" class="popup">
      <div class="popBody">
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain text-center">
          <div class="icon success">
            <font-awesome-icon
              icon="fa-solid fa-circle-check"
              size="2x"
            ></font-awesome-icon>
          </div>
          <span class="fL"> {{ successMessage }} </span>
        </section>
        <section class="popFooter">
          <a class="button submit rounded" @click.prevent="popClose">
            <span class="fM">確定</span>
          </a>
        </section>
      </div>
    </section>
    <!-- Popup (重新綁定) -->
    <section id="popRenew" class="popup">
      <div class="popBody">
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <div class="icon error">
            <i class="fa-regular fa-circle-exclamation fa-2x"></i>
          </div>
          <span class="fL">載具驗證碼已被修改請重新綁定</span>
        </section>
        <section class="popFooter">
          <a class="button submit rounded" @click.prevent="popRenewClose">
            <span class="fM">確定</span>
          </a>
        </section>
      </div>
    </section>
    <!-- Popup (解除綁定) -->
    <section id="popDelete" class="popup">
      <div class="popBody">
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <div class="icon error">
            <font-awesome-icon
              icon="fa-solid fa-circle-exclamation"
              size="2x"
            />
          </div>
          <span class="fL">確定解除綁定載具？</span>
          <span class="fS gray">解除綁定後部分功能將無法正常使用</span>
        </section>
        <section class="popFooter">
          <a class="button white rounded" @click.prevent="popDeleteClose">
            <span class="fM">取消</span>
          </a>
          <a class="button error rounded" @click.prevent="removeCarrier">
            <span class="fM">確定解除</span>
          </a>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Spinner from "@/components/Spinner";
import VueBarcode from "vue-barcode";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "欄位必填",
});

export default {
  name: "CarrierEdit",
  components: {
    Spinner,
    barcode: VueBarcode,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      userData: {
        name: "",
        gender: "",
        birthdate: "",
        idno: "",
        phoneNumber: "",
        email: "",
        fullAddress: "",
        cardNo: "",
      },
      originalCardNo: "",
      successMessage: "",
      isProcessing: false,
      cardEncrypt: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        next("/error");
      }
    });
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    displayUserImage() {
      return this.userInfo ? this.userInfo.picture : "";
    },
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    callUserDataAPI() {
      var config = {
        method: "get",
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getUserData() {
      this.callUserDataAPI()
        // this.dummyUserData()
        .then((res) => {
          this.$store.commit("updateUserInfo", res.data);
          this.userData = res.data;
          this.originalCardNo = res.data.cardNo ? res.data.cardNo : null;
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$router.push("/error");
        });
    },
    popSuccess(msg) {
      this.successMessage = msg;
      var popSuccess = document.getElementById("popSuccess");
      popSuccess.style.display = "flex";
    },
    popClose() {
      var popSuccess = document.getElementById("popSuccess");
      popSuccess.style.display = "none";
    },
    popRenew() {
      var popRenew = document.getElementById("popRenew");
      popRenew.style.display = "flex";
    },
    popRenewClose() {
      var popRenew = document.getElementById("popRenew");
      popRenew.style.display = "none";
    },
    popDelete() {
      var popDelete = document.getElementById("popDelete");
      popDelete.style.display = "flex";
    },
    popDeleteClose() {
      var popDelete = document.getElementById("popDelete");
      popDelete.style.display = "none";
    },
    removeCarrier() {
      this.isProcessing = true;
      this.popDeleteClose();
      let data = Object.assign({}, this.userData);
      data.cardNo = null;
      data.cardEncrypt = null;
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        method: "PUT",
        data: data,
      };
      this.$http(config)
        .then((res) => {
          console.log("res: ", res);
          this.popSuccess("載具移除成功");
          this.getUserData();
          this.cardEncrypt = "";
        })
        .catch((err) => {
          console.log("err: ", err);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    callUpdateProfileAPI() {
      let data = Object.assign({}, this.userData);
      data.cardNo = data.cardNo.trim();
      data.cardEncrypt = this.cardEncrypt;
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}`,
        method: "PUT",
        data: data,
      };
      console.log("config: ", config);
      return this.$http(config);
    },
    save(valid, passes) {
      passes();
      if (!valid) return;
      this.isProcessing = true;
      this.callUpdateProfileAPI()
        .then((res) => {
          console.log("res: ", res);
          this.popSuccess("載具設定成功");
          this.getUserData();
        })
        .catch((err) => {
          console.log("err: ", err);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.body .carrier {
  padding: 16px;
  width: 92% !important;
  margin: 0 auto;
  a {
    padding-right: 15px;
  }
}
</style>
